@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/17009842-ac84-4271-bdeb-38a10ec82592.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/ad28b01a-1f06-469f-8f07-e32d06292237.woff") format("woff");
  font-weight: 250;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/bb91ecb7-665e-4b32-a8a3-1df1a01b6c07.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/b8497d3a-303d-410f-b147-bb1380590716.woff") format("woff");
  font-weight: 250;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/58980974-5ea5-4642-baf1-635a99d450d8.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/7f42b6b3-1524-42bd-acb0-5e506ab664af.woff") format("woff");
  font-weight: 265;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/adcd6b1f-3ea8-49a7-b4d6-b4a9ca96bd24.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/7033c3f9-a861-47f9-8508-a77c1bd9a50c.woff") format("woff");
  font-weight: 265;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/7f92c560-e510-46cd-99b9-b9bf2986496f.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/730dab86-0b8f-4bda-83f6-d0b77cd67e0f.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/c56c888d-af3f-4f89-aa8f-95eeff4274d9.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/c7f7227a-47e4-4460-87aa-6cad85fd8c93.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/542ea8aa-a893-492c-8f57-fb8bbd4e4c50.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/cb278cac-c203-4fd3-8365-c6e73b4c10b3.woff") format("woff");
  font-weight: 350;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/d7efe636-4e21-4ded-aeec-db7d3622b4a3.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/87be5aaf-7b6a-4c6d-8c61-f70f075c00f3.woff") format("woff");
  font-weight: 350;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/d07e46ea-8260-4883-bfa3-3a557cb93598.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/91318483-1660-41d7-be20-a7fedf82ee86.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/c753ccc5-37c2-4d1a-ac4d-9b1878ad732c.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/ca0fc60d-2c5a-47a7-a4a2-4dabb0eacc64.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/43babb0e-6086-481c-a47c-bf8d35474219.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/50605419-ed6b-469a-bd38-50497cc71558.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/3b7fd647-f2ef-4e3a-b987-e161805ab603.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/74da044c-bcde-464b-b17d-61be728abb86.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/14028c16-9f95-49e1-a346-a3ff8f3c63f5.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/8990729d-b642-4528-97e2-11c0c1ea297e.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/cc145d49-4593-466c-87c8-d8ff7ec6d6b8.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/bb8922e4-8c09-4dba-9739-a46a1ef4b23c.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/6004dfed-29e5-4130-a90d-0de554ad3f2a.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/25727e6e-2d59-480c-9330-b256fa239b66.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/5c84984d-948d-4eb3-9a38-de90dff7a6d8.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/46879fd3-6c07-4616-96f4-51f868357c48.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/eeb09fee-c781-414a-adc4-7fa0c214d948.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/f96ec35e-6ead-41d9-8086-464ad0d315fe.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/2852248e-9d88-4839-8594-c05b763edd32.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/15073744-9cf2-47b9-b4ca-1bbf8d55a8ae.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/3a56ec57-7941-4720-992a-8c1c194a3806.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/8db4ca93-552b-4f68-8b06-fd7bb926173d.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face{
  font-family:"TT Commons";
  src:url("../fonts/tt_commons/Fonts/f15f45ae-80cd-4240-8960-20f2150654d1.woff2") format("woff2"),url("../fonts/tt_commons/Fonts/36356599-eec5-40cd-ad1a-cbce9b44389b.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Light.ttf")  format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Regular.ttf")  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Medium.ttf")  format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-SemiBold.ttf")  format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face{
  font-family:"Hind";
  src:url("../fonts/Hind/Hind-Bold.ttf")  format('truetype');
  font-weight: 700;
  font-style: normal;
}


*{
  box-sizing: border-box;
}


html, body{
  max-width: 100%;
  max-height:100vh;
}

html{
  /* font-family: "TT Commons", "Hind", sans-serif; */
  font-size:10px;
  // font-size: 16px;
  line-height:1.5rem;
  font-weight:300;
  margin: 0;
  overscroll-behavior: none;
  width:100vw;
    height:100vh;
    overflow: hidden;
}

body{
  margin:0;
  padding: 0 !important;
  font-weight:300;
  background-color: #F7F9FD;
}

ul{
  padding-inline-start: 0;
  margin: 0;
}

ul li{
    list-style-type: none;
}

p, span, input, button{
  font-family: "Hind";
}

a{
  text-decoration: none;
  cursor:pointer;
  font-size:1.3rem;
  -webkit-tap-highlight-color: transparent;
}

button{
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border:none;
  background-color: transparent;
  /* &:focus{
    outline: 0;
    -webkit-box-shadow: 0 0 0 white, 0 0 0 4px $plum;
    box-shadow: 0 0 0 2px white, 0 0 0 4px $plum;
    border-radius:4px;
  } */
}

button.primary{
  font-weight:700;
  height:30px;
  border-radius:40px;
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 78.12%, #BD0071 100%);
  color:white;
  border:none;
  min-width:80px;
  padding:0 20px;
}

button.secondary{
  // font-weight:700;
  // height:30px;
  // border-radius:40px;
  // background: white;
  // color:grey;
  // min-width:80px;
  // padding:0 20px;
  // border: 1px solid #E8EEFA;
  border-radius: 40px;
  border: 2px solid var(--Swatch-Dark-800, #272D39);  
  font-family: "TT Commons";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.4px;
  display:flex;
  align-items: center;
  justify-content: center;
  height:32px;
  min-width:100px;
  &:hover{
    background-color: #eaeffa
  }
  &[disabled]{
    opacity:0.8
  }
}

.link{
  font-size:1.4rem;
  background:none;
  border:none;
  color:#655591;
  text-decoration: underline;
  // margin:24px 0;
  // width:100%;
  // text-align: center;
  /* :hover{
    text-decoration: underline;
  } */
}

input:focus, textarea:focus{
  outline:none;
}

// .audio-container{
//   position: absolute;
//   top: 15px;
//   right: 114px;
// }

// .audio-player{
//   width: 168px;
//   background: #FCFCFD;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   gap: 10px;
//   position: absolute;
//   top: 0;
//   right: 0;
// }

// .audio-player>div {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

// .audio-player button{
//   height:25px;
// }

// .audio-player>div span{
//   font-size:18px;
//   font-weight:500;
// }

.main{
  height: 100vh;
  width:100vw;
  /* background-color: blue; */
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* border: 2px solid purple; */
}

.reader{
  height: 100vh;

}

.tab.outside{
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  right:-56px;
}

.left-drawer{
  /* border-right: 1px solid #DDE3EE; */
}

.left-drawer .tab{
  right:0;
  border-radius: 0px 56px 56px 0px;
}

.left-drawer .tab.outside{
  right:-56px;
}

.right-drawer{
  border-left: 1px solid #DDE3EE;
}

.right-drawer .tab{
  left:0;
  border-radius: 56px 0px 0px 56px;
}

.right-drawer .tab.outside{
  left:-56px;
}

/* .settings-menu{
  width: 140px;
  position: absolute;
  right:20px;
  top: 60px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px 0 16px 16px;
} */

/* .settings-menu span{
  font-size:14px;
  font-weight:700;
}

.settings-menu button{
  display: block;
  width:100%;
  padding: 16px 0 0 0;
  text-align: left;
} */

.context-menu{
  position: absolute;
  right:0;
  top: 0;
  width: 288px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  // padding:16px;
}

.context-menu button{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  font-size: 16px;
  border-bottom: 1px solid rgb(232, 238, 250);
  padding:16px;
}

.context-menu button img{
  margin-right:8px;
  width: 20px;
  height: 20px;
}

// .context-menu button:not(:last-of-type){
//   margin-bottom: 18px;
// }

.context-menu button>div:not(.new-tag){
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.context-menu button .highlight-colors{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
}

.new-tag{
  background-color:#E8EEFA;
  border-radius: 8px;
  width: 57px;
  height: 24px;
  font-size: 16px;
  font-weight:500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookmark-button-container{
  /* position: absolute; */
  height:25px;
  /* left:50%; */
  /* margin-left: 145px;
  bottom:20px; */
  display: flex;
  align-items: center;
}

.bookmark-button-container .bookmark-button{
  height:25px;
  width: 25px;
}

.bookmark-menu{
  position: absolute;
  width: 336px;
  min-height:120px;
  top: 60px;
  right: 20px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.bookmark-menu .bookmark-list{
  margin-top:0;
  min-height:120px;
  max-height: 50vh;
  overflow:auto;
}

.bookmark-menu .bookmark-card{
  display:flex;
  justify-content: space-between;
  align-items: center;
  border:none;
  margin:0;
  padding-bottom:0;
}

.bookmark-menu .remove-button{
  width:100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7F9FD;
  color: #655591;
  font-size: 16px;
  margin-top:16px;
}

.bookmark-empty{
  font-size:12px;
  text-align:center;
  position: relative;
  top:30px;
}

.MuiSvgIcon-root {
  fill: black;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 18px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;

  width: 28px;
  height: 28px;
  margin-top: -1px;
  margin-left: -1px;
}

.modal{
  background: #F7F9FD;
  box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2006;
  padding:24px;
  p{
    @include p;
  }
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom:16px;
    padding-bottom:16px;
    border-bottom:1px solid #E8EEFA;
    h3{
      @include h3;
    }
    button{
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
  }
}

button.toggle{
  position: relative;
  display: block;
  width: 56px;
  height: 28px;
  padding: 0;
  border: 0;
  border-radius: 22px;
  margin: 4px 0;
  color: #fff;
      background: rgb(247, 249, 253);
    border: 0.5px solid rgba(155, 161, 173, 0.25) !important;
  cursor: pointer;
  transition: background .3s;
  overflow: visible;
  min-width: 50px;
  flex-shrink: 0;
  &:before {
      content: "";
      // background: #FEFEFE;
      background: rgb(5, 17, 42);
      display: block;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      height: 18px;
      transition: left .3s;
      border-radius: 100%;
      pointer-events: none;
  }
  &.on {
    background: #6D0176;
    &:before{
      left: 30px;
      border: 1px solid #959FA6;
      background: #F7F7F7;
    }
  }
}

.custom-tooltip{
  background-color: #101010 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  pointer-events: none;
  &.bookmark{
    background-color: #005A70 !important;
  }
  span{
    display: flex;
    align-items: center;
    img{
      margin-right:8px;
    }
  }
}

.fake-select{
  height:36px;
  flex-grow: 1;
  padding:8px;
  border:1px solid #e8eefa;
  border-radius:4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color:white;   
}

.fake-select span.label{
  margin-top: 2px;
  pointer-events: none;
}

// .toolbar:not(.expanded)~div .reader footer{
//   margin-left:-86px;
//   width:calc(100% + 86px);
// }
