@keyframes spinAnimation {
  0% { transform: rotate(0deg) }
 100% { transform: rotate(360deg) }
}

.search{

  .results-panel{
      height:calc(100% - 165px);
      overflow:auto;
  }
  
  .tab-container {
    // align-self: flex-end;
    // left: 50%;
    // position: absolute;
    // -webkit-transform: translateX(-50%) translateY(0);
    // transform: translateX(-50%) translateY(0);
    width: 100%;
    padding: 0;
    margin-top:24px;
}
  .tab-container .tab-list-container {
    position: relative;
  }  
  .tab-container .tab-slider {
      background: linear-gradient(135deg, #e49d2d, #ea0b7e 51.09%, #851b4a);
      bottom: 0;
      height: 4px;
      position: absolute;
      transition: left .3s ease, width .2s ease;
      width: auto;
  }
  .tab-container .tab-list {
      display: flex;
      gap: 16px;
      margin: 0;
      overflow-x: auto;
      justify-content: space-between;
      border-bottom: 1px solid #E8EEFA;
      li{
        // display: inline-block;
        flex:1;
        .tab-action {
            align-items: center;
            background: none;
            border: 0;
            border-bottom: 4px solid transparent;
            color: #020917;
            cursor: pointer;
            display: inline-flex;
            flex-direction: column;
            font-size: 20px;
            font-weight: 400;
            justify-content: space-between;
            letter-spacing: .4px;
            line-height: 24px;
            // margin: 0 10px;
            padding: 6px 0 16px;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;
            width:100%;
            &[aria-selected=true] {
                border-bottom: 4px solid transparent;
                font-weight: 700;
            }
           .hidden-bold {
              font-weight: 700;
              height: 0;
              overflow: hidden;
              pointer-events: none;
              -webkit-user-select: none;
              user-select: none;
              visibility: hidden;
              width:100%;
          }
        }
      }
  }

    .search-panel{
      .expand-collapse{
        display:flex;
        align-items: center;
        margin:16px 0 16px 16px;
        button, span{
          font-family: "TT Commons";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 114.286% */
          letter-spacing: 0.24px;
        }
        button:hover{
          color:#655591;
        }
      }

      .chapters{
        li{
          display: flex;
          align-items: center;
          padding: 0 16px;
          border-top: 1px solid rgb(232, 238, 250);
          img{
            transform: rotate(270deg);
            width:12px;
            height:12px;
            margin-right:16px;
          }
        }
        button{
          display:flex;
          align-items: center;
          justify-content: space-between;
          width:100%;
          text-align: left;
          gap:16px;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
          p:last-of-type{
            display:block;
            white-space: nowrap;
          }
          &:hover{
            text-decoration: underline;
          }
        }
      }

      &#panel-study, &#panel-custom{
        .chapters li{
          button{
            border-bottom: 1px solid rgb(232, 238, 250);
          }
          img{
            transform: unset;
          }
        } 
      }

      &#panel-custom{
        .note-card{
          width:calc(100% - 74px);
          margin-left:50px;
          margin-right:24px;
          display: flex;
          align-items: center;
          gap:30px;
          cursor: pointer;
          .highlight{
              padding:8px;
              background: rgba(244, 217, 123, 0.25);
              border-left: 4px solid #EEA42E;
              font-size: 14px;
              font-weight: 400;
              line-height: 150%;
          }
          &>p{
            margin: 0;
            margin-top:16px;
          }
        }
      }

      &#panel-study{
        .video-card{
          width:calc(100% - 74px);
          margin-left:50px;
          margin-right:24px;
          border-bottom:1px solid rgb(232, 238, 250);
          padding-bottom:16px;
          cursor:pointer;
          .video-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            span{
              display: flex;
              align-items:center;
              gap:6px;
              svg{
                width:16px;
                height:16px;
              }
              span{
                font-size:16px;
                font-weight:400;
                color:rgb(5, 17, 42);
                margin-top:2px;
              }
            } 
          }
          .video{
            position: relative;
            img{
              width:100%;
            }
            svg{
              position: absolute;
              top:50%;
              left:50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }


    &.drawer-panel{
        height: calc(100vh - 90px);
        overflow-y: hidden;
        position: relative;
        padding-left:16px;
      }
      .search-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
      }
    .search-container{
        background: rgb(254, 254, 254);
        border: 1px solid rgb(232, 238, 250);
        color: rgb(5, 17, 42);
        width: 306px;
        height: 40px;
        padding: 12px 4px 12px 16px;
        display: flex;
        align-items: center;
        form{
          width: 100%;
          height:100%;
          display:flex;
          align-items: center;
          img{
            width:20px;
            height:20px;
            margin-top:6px;
          }
        }
        input{
            background: transparent;
            border:none;
            margin-left: 16px;
            font-size: 16px;
            margin-top: 2px;
            width: 100%;
        }
    }
    .search-header .filter-button{
        color: #6a7070;
        border: 1px solid rgb(232, 238, 250);
        background: rgb(254, 254, 254);
        width: 40px;
        height: 40px;
        border-radius: 24px;
        display:flex;
        align-items: center;
        justify-content: center;
        &[disabled]{
          opacity: 0.5;
        }
    }
    .empty, .no-results{
        height: 100%;
        position: relative;
        &:after{
          content: '';
          display: block;
          position: absolute;
          right: 0;
          width: 112%;
          height: 521px;
          background-repeat: no-repeat;
          background-position: center;
    
        }
        .title{
          font-size: 20px;
          font-weight:700;
          text-align: center;
          width: 80%;
          margin:40px auto 0 auto;
        }
        .p{
          text-align: center;
        //   width: 80%;
          margin:0 auto;
        }
      }

      .empty{
        &:after{
          bottom: 88px;
          background-image:url('../images/illustrations/search-empty.svg')
        }
      }
      .no-results{
        &:after{
          bottom: 0;
          background-image:url('../images/illustrations/search-no-results.svg')
        }
        button.primary{
          margin: 20px auto;
          display: block;
        }
      }
    .spinner{
      width:16px;
      height:16px;
      display:flex;
      align-items:center;
      justify-content:center;
      // position:absolute;
      // top:50%;
      // left:50%;
      // transform:translate(-50%, -50%);
      margin: 100px auto;
      transform-origin:center;
      animation: 1500ms infinite linear spinAnimation;
    }

    .ai-overview-btn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:calc(100% - 32px);
      margin:16px 16px -1px 16px;
      padding: 8px 20px;
      border-radius: 8px;
      border: 1px solid var(--Border-lightTheme--border, #DDE3EE);
      background: var(--background-light-theme-bg-level-2, #E8EEFA);
      &>div{
        display: flex;
        align-items: center;
        font-family: "TT Commons";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
        letter-spacing: 0.4px;
        img{
          width:32px;
          height:32px;
          margin-right:8px;
        }
      }
      &.expanded{
        border-radius: 8px 8px 0 0;
        &>img{
          transform: rotate(180deg);
        }
      }
    }

    .ai-response{
      padding: 0 16px;
      // height: calc(100% - 220px);
      overflow-y: auto;
      padding-bottom:40px;
      margin: 0 16px 0 16px;
      padding: 8px 20px;
      border-radius: 0 0 8px 8px;
      border: 1px solid var(--Border-lightTheme--border, #DDE3EE);
      p{
        font-family: "TT Commons";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.4px;
        b{
          text-decoration: underline;
        }
      }
      &>span{
        font-size:18px;
        // &:hover{
        //   text-decoration: underline;
        //   cursor:pointer;
        // }
      }
      button.secondary{
        // margin: 0 auto 16px auto;
        padding: 0 16px;
        &:hover{
          background-color:rgba(39, 45, 57, 1);
          color:white;
        }
      }
      .more-btns{
        display:flex;
        gap:8px;
        align-items: center;
        justify-content: center;
        width:fit-content;
        margin:24px auto;
      }
      h2{
        color: #000;
        font-family: "TT Commons";
        font-size: 18px;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.4px;
        border-bottom: 1px solid #DDE3EE;
      }
      ul{
        margin-bottom:16px;
      }
      ul.learn-more{
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:16px;
      }
      ul li.article{
        background: #D9D9D9;
        height: 72px;
        flex:1;
        cursor:pointer;
      }
      ul li button{
          width:100%;
          display:flex;
          align-items:center;
          justify-content:space-between;
          padding: 16px 8px;
          border-radius: 8px;
          border: 1px solid var(--Border-lightTheme--border, #DDE3EE);
          margin-bottom:8px;
          font-size: 18px;
          text-align:left;
          span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          &:hover{
            background-color: #eaeffa
          }
      }
      .ai-feedback{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ai-rating{
            // width: 64px;
            gap:16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button{
              width: 16px;
              height: 16px;
              display:flex;
              align-items: center;
              justify-content: center;
            }
          }
      }
    }
}