@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/17009842-ac84-4271-bdeb-38a10ec82592.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/ad28b01a-1f06-469f-8f07-e32d06292237.woff") format("woff");
  font-weight: 250;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/bb91ecb7-665e-4b32-a8a3-1df1a01b6c07.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/b8497d3a-303d-410f-b147-bb1380590716.woff") format("woff");
  font-weight: 250;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/58980974-5ea5-4642-baf1-635a99d450d8.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/7f42b6b3-1524-42bd-acb0-5e506ab664af.woff") format("woff");
  font-weight: 265;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/adcd6b1f-3ea8-49a7-b4d6-b4a9ca96bd24.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/7033c3f9-a861-47f9-8508-a77c1bd9a50c.woff") format("woff");
  font-weight: 265;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/7f92c560-e510-46cd-99b9-b9bf2986496f.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/730dab86-0b8f-4bda-83f6-d0b77cd67e0f.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/c56c888d-af3f-4f89-aa8f-95eeff4274d9.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/c7f7227a-47e4-4460-87aa-6cad85fd8c93.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/542ea8aa-a893-492c-8f57-fb8bbd4e4c50.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/cb278cac-c203-4fd3-8365-c6e73b4c10b3.woff") format("woff");
  font-weight: 350;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/d7efe636-4e21-4ded-aeec-db7d3622b4a3.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/87be5aaf-7b6a-4c6d-8c61-f70f075c00f3.woff") format("woff");
  font-weight: 350;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/d07e46ea-8260-4883-bfa3-3a557cb93598.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/91318483-1660-41d7-be20-a7fedf82ee86.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/c753ccc5-37c2-4d1a-ac4d-9b1878ad732c.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/ca0fc60d-2c5a-47a7-a4a2-4dabb0eacc64.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/43babb0e-6086-481c-a47c-bf8d35474219.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/50605419-ed6b-469a-bd38-50497cc71558.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/3b7fd647-f2ef-4e3a-b987-e161805ab603.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/74da044c-bcde-464b-b17d-61be728abb86.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/14028c16-9f95-49e1-a346-a3ff8f3c63f5.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/8990729d-b642-4528-97e2-11c0c1ea297e.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/cc145d49-4593-466c-87c8-d8ff7ec6d6b8.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/bb8922e4-8c09-4dba-9739-a46a1ef4b23c.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/6004dfed-29e5-4130-a90d-0de554ad3f2a.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/25727e6e-2d59-480c-9330-b256fa239b66.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/5c84984d-948d-4eb3-9a38-de90dff7a6d8.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/46879fd3-6c07-4616-96f4-51f868357c48.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/eeb09fee-c781-414a-adc4-7fa0c214d948.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/f96ec35e-6ead-41d9-8086-464ad0d315fe.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/2852248e-9d88-4839-8594-c05b763edd32.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/15073744-9cf2-47b9-b4ca-1bbf8d55a8ae.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/3a56ec57-7941-4720-992a-8c1c194a3806.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/8db4ca93-552b-4f68-8b06-fd7bb926173d.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  src: url("../fonts/tt_commons/Fonts/f15f45ae-80cd-4240-8960-20f2150654d1.woff2") format("woff2"), url("../fonts/tt_commons/Fonts/36356599-eec5-40cd-ad1a-cbce9b44389b.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
* {
  box-sizing: border-box;
}

html, body {
  max-width: 100%;
  max-height: 100vh;
}

html {
  /* font-family: "TT Commons", "Hind", sans-serif; */
  font-size: 10px;
  line-height: 1.5rem;
  font-weight: 300;
  margin: 0;
  overscroll-behavior: none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0 !important;
  font-weight: 300;
  background-color: #F7F9FD;
}

ul {
  padding-inline-start: 0;
  margin: 0;
}

ul li {
  list-style-type: none;
}

p, span, input, button {
  font-family: "Hind";
}

a {
  text-decoration: none;
  cursor: pointer;
  font-size: 1.3rem;
  -webkit-tap-highlight-color: transparent;
}

button {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: none;
  background-color: transparent;
  /* &:focus{
    outline: 0;
    -webkit-box-shadow: 0 0 0 white, 0 0 0 4px $plum;
    box-shadow: 0 0 0 2px white, 0 0 0 4px $plum;
    border-radius:4px;
  } */
}

button.primary {
  font-weight: 700;
  height: 30px;
  border-radius: 40px;
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 78.12%, #BD0071 100%);
  color: white;
  border: none;
  min-width: 80px;
  padding: 0 20px;
}

button.secondary {
  border-radius: 40px;
  border: 2px solid var(--Swatch-Dark-800, #272D39);
  font-family: "TT Commons";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 100px;
}
button.secondary:hover {
  background-color: #eaeffa;
}
button.secondary[disabled] {
  opacity: 0.8;
}

.link {
  font-size: 1.4rem;
  background: none;
  border: none;
  color: #655591;
  text-decoration: underline;
  /* :hover{
    text-decoration: underline;
  } */
}

input:focus, textarea:focus {
  outline: none;
}

.main {
  height: 100vh;
  width: 100vw;
  /* background-color: blue; */
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* border: 2px solid purple; */
}

.reader {
  height: 100vh;
}

.tab.outside {
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  right: -56px;
}

.left-drawer {
  /* border-right: 1px solid #DDE3EE; */
}

.left-drawer .tab {
  right: 0;
  border-radius: 0px 56px 56px 0px;
}

.left-drawer .tab.outside {
  right: -56px;
}

.right-drawer {
  border-left: 1px solid #DDE3EE;
}

.right-drawer .tab {
  left: 0;
  border-radius: 56px 0px 0px 56px;
}

.right-drawer .tab.outside {
  left: -56px;
}

/* .settings-menu{
  width: 140px;
  position: absolute;
  right:20px;
  top: 60px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px 0 16px 16px;
} */
/* .settings-menu span{
  font-size:14px;
  font-weight:700;
}

.settings-menu button{
  display: block;
  width:100%;
  padding: 16px 0 0 0;
  text-align: left;
} */
.context-menu {
  position: absolute;
  right: 0;
  top: 0;
  width: 288px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.context-menu button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid rgb(232, 238, 250);
  padding: 16px;
}

.context-menu button img {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.context-menu button > div:not(.new-tag) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.context-menu button .highlight-colors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
}

.new-tag {
  background-color: #E8EEFA;
  border-radius: 8px;
  width: 57px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookmark-button-container {
  /* position: absolute; */
  height: 25px;
  /* left:50%; */
  /* margin-left: 145px;
  bottom:20px; */
  display: flex;
  align-items: center;
}

.bookmark-button-container .bookmark-button {
  height: 25px;
  width: 25px;
}

.bookmark-menu {
  position: absolute;
  width: 336px;
  min-height: 120px;
  top: 60px;
  right: 20px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.bookmark-menu .bookmark-list {
  margin-top: 0;
  min-height: 120px;
  max-height: 50vh;
  overflow: auto;
}

.bookmark-menu .bookmark-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin: 0;
  padding-bottom: 0;
}

.bookmark-menu .remove-button {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7F9FD;
  color: #655591;
  font-size: 16px;
  margin-top: 16px;
}

.bookmark-empty {
  font-size: 12px;
  text-align: center;
  position: relative;
  top: 30px;
}

.MuiSvgIcon-root {
  fill: black;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 18px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  width: 28px;
  height: 28px;
  margin-top: -1px;
  margin-left: -1px;
}

.modal {
  background: #F7F9FD;
  box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2006;
  padding: 24px;
}
.modal p {
  font-family: "Hind";
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  line-height: 150%;
}
.modal .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E8EEFA;
}
.modal .header h3 {
  font-family: "TT Commons";
  font-size: 3rem;
  font-weight: 700;
  font-style: normal;
  color: #1C222E;
}
.modal .header button {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

button.toggle {
  position: relative;
  display: block;
  width: 56px;
  height: 28px;
  padding: 0;
  border: 0;
  border-radius: 22px;
  margin: 4px 0;
  color: #fff;
  background: rgb(247, 249, 253);
  border: 0.5px solid rgba(155, 161, 173, 0.25) !important;
  cursor: pointer;
  transition: background 0.3s;
  overflow: visible;
  min-width: 50px;
  flex-shrink: 0;
}
button.toggle:before {
  content: "";
  background: rgb(5, 17, 42);
  display: block;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 19px;
  height: 18px;
  transition: left 0.3s;
  border-radius: 100%;
  pointer-events: none;
}
button.toggle.on {
  background: #6D0176;
}
button.toggle.on:before {
  left: 30px;
  border: 1px solid #959FA6;
  background: #F7F7F7;
}

.custom-tooltip {
  background-color: #101010 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  pointer-events: none;
}
.custom-tooltip.bookmark {
  background-color: #005A70 !important;
}
.custom-tooltip span {
  display: flex;
  align-items: center;
}
.custom-tooltip span img {
  margin-right: 8px;
}

.fake-select {
  height: 36px;
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #e8eefa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.fake-select span.label {
  margin-top: 2px;
  pointer-events: none;
}

.reader-header {
  height: 56px;
  width: 100%;
  background-color: #F7F9FD;
  /* border-bottom: 1px solid #DDE3EE; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reader-header .chapter {
  font-family: "TT Commons";
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  color: #1C222E;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 430px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.reader-header .avatar-button {
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.reader-header .avatar-button.active .avatar {
  color: white;
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 78.12%, #BD0071 100%);
}
.reader-header .avatar-button.active .arrow {
  transform: rotate(180deg);
}

.reader-header .avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid #BCC1CB;
  color: #020917;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "TT Commons";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  background: #F7F9FD;
  border: 1px solid #E8EEFA;
  margin-right: 4px;
}

.carousel {
  height: calc(100% - 120px);
  /* width:calc(100% - 50px); */
  margin: 0;
  /* margin-left: 50px; */
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.carousel.cleanmode {
  height: calc(100% - 64px);
}

.carousel::-webkit-scrollbar, .carousel li.slide::-webkit-scrollbar {
  display: none;
}

.carousel li.slide {
  position: relative;
  height: calc(100vh - 62px);
  min-width: 100%;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  scroll-padding: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 1.3rem;
}

.carousel li.slide p {
  font-size: 1.3rem;
  line-height: 200%;
  text-transform: none;
}

footer {
  background-color: #F7F9FD;
  box-shadow: 1px -4px 10px 0px rgba(221, 227, 238, 0.64);
  height: 64px;
  width: 100%;
  position: relative;
  transition: margin 300ms ease;
  position: absolute;
  bottom: 0;
  left: 0;
}
footer .inner-wrapper {
  padding: 0 12px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 749px) {
  footer .inner-wrapper {
    overflow-x: auto;
  }
}
footer .white-sliver {
  width: 100%;
  height: 1.5px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}
footer .go-to-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 870px) {
  footer .go-to-page {
    position: unset;
    transform: unset;
  }
}
footer .go-to-page .go-to-page-container {
  display: flex;
  gap: 4px;
  align-items: center;
}
footer .go-to-page button {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .go-to-page .input-container {
  width: 72px;
  height: 40px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
footer .go-to-page .input-container input {
  width: 80%;
  padding: 8px;
  background: transparent;
  border: none;
  font-size: 16px;
  text-align: center;
}
footer .go-to-page .input-container .bookmark-button {
  position: absolute;
  right: 8px;
}
footer .go-to-page span {
  font-size: 18px;
  font-weight: 400;
  font-size: 16px;
  color: #60646D;
  display: block;
  white-space: nowrap;
}
footer .zen-toggle {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
footer .zen-toggle .toggle {
  border: 0.5px solid #020917 !important;
}
footer .zen-toggle .toggle.on {
  background: rgb(5, 17, 42) !important;
}
footer .zen-toggle label {
  margin-right: 8px;
  font-family: "TT Commons";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.drawer {
  height: 100%;
  overflow: hidden;
  width: 416px;
  background-color: #F7F9FD;
  box-shadow: 1px -1px 10px rgba(213, 219, 232, 0.64);
  border-radius: 0px 16px 16px 0px;
  z-index: 1999;
  position: relative;
}

.drawer.mini {
  width: 102px;
}

.drawer p {
  font-family: "Hind";
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  line-height: 150%;
}

.drawer .tab {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

/* .drawer:not(.mini) .drawer-header{
    border-bottom: 1px solid rgba(155, 161, 172, 0.2);
} */
.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 32px;
  border-bottom: 1px solid rgba(155, 161, 172, 0.2);
}

.drawer.mini .drawer-header {
  padding: 0;
}

.drawer-header h2 {
  font-family: "TT Commons";
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #1C222E;
  margin: 0;
}

.drawer-header .close, .drawer-header .expand-collapse {
  width: 46px;
  height: 46px;
  border-radius: 40px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.drawer-header .close {
  background-image: url("../images/icons/close.svg");
}

.drawer.mini .drawer-header .close, .drawer.mini .drawer-header .expand-collapse {
  margin: 20px auto 20px 34px;
}

.drawer-header .expand-collapse.expand {
  background-image: url("../images/icons/expand-drawer.svg");
}

.drawer-header .expand-collapse.collapse {
  background-image: url("../images/icons/collapse-drawer.svg");
}

.toggle-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(155, 161, 173, 0.25);
  height: 40px;
  padding: 3px;
  position: relative;
  margin-top: 16px;
}
.toggle-tabs button.active:after {
  content: "";
  display: block;
  height: 3px;
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
  border-radius: 15px 15px 0 0;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  transition: all 300ms ease;
}

.toggle-tabs.bookmarks:after {
  /* transform: translate(-50%, 0); */
}

/* .toggle-tabs.recent:after{
    right:2px;
} */
.toggle-tabs button {
  font-family: "TT Commons";
  font-size: 18px;
  color: black;
  font-weight: 500;
  padding: 8px;
  flex-grow: 1;
  position: relative;
  background: none;
  border: none;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  z-index: 1;
  white-space: nowrap;
}

.toggle-tabs button.active {
  font-weight: 700;
}

.toggle-tabs button:focus {
  box-shadow: none;
}

.toc-list {
  margin-left: 16px;
  height: calc(100vh - 160px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.toc-list .book-info {
  margin-top: 16px;
  margin-left: 16px;
  padding-top: 16px;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 260px;
}
.toc-list .book-info img {
  width: 50px;
  height: 64px;
  margin: 0 16px 0 0;
  float: left;
  border-radius: 4px;
}
.toc-list .book-info p {
  margin: 0;
}
.toc-list .book-info p:first-of-type {
  font-family: "TT Commons";
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  font-style: normal;
  margin-top: 8px;
  color: rgb(5, 17, 42);
}
.toc-list .book-info p:last-of-type {
  color: inherit;
  font-family: "Hind", Helvetica, Verdana, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 120%;
  padding-top: 8px;
}
.toc-list ul.chapters {
  margin-left: 16px;
  margin-top: 40px;
  clear: both;
}
.toc-list ul.chapters > li div {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  border-top: 1px solid #E8EEFA;
  width: 110%;
  margin-left: -16px;
  padding-left: 16px;
  position: relative;
}
.toc-list ul.chapters > li div.active:before {
  content: "";
  display: block;
  height: 60%;
  width: 4px;
  background: linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%);
  border-radius: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.toc-list ul.chapters > li div button {
  background: none;
  border: none;
  text-align: left;
  line-height: 150%;
  padding: 16px 16px 16px 0;
  width: 80%;
  font-size: 14px;
}
.toc-list ul.chapters > li div button:hover {
  text-decoration: underline;
}
.toc-list ul.chapters > li div button:first-of-type {
  width: 16px;
  height: 16px;
  margin-top: 11px;
  margin-right: 16px;
  background-image: url("../images/icons/chevron-blue copy.svg");
  transform: rotate(-90deg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.toc-list ul.chapters > li div button:first-of-type.expanded {
  transform: rotate(0deg);
  background-image: url("../images/icons/chevron-blue.svg");
}
.toc-list ul.chapters > li div button p {
  pointer-events: none;
  margin: 0;
}
.toc-list ul.sections {
  margin-left: 30px;
}
.toc-list ul.sections li {
  position: relative;
}
.toc-list ul.sections li.active:before {
  content: "";
  display: block;
  height: 60%;
  width: 4px;
  background: linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%);
  border-radius: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.toc-list ul.sections li button {
  font-size: 14px;
  background: none;
  border: none;
  text-align: left;
  line-height: 150%;
  padding: 16px 0 16px 16px;
  border-top: 1px solid #E8EEFA;
  width: 95%;
}
.toc-list ul.sections li button:hover {
  text-decoration: underline;
}
.toc-list ul.sections.hidden {
  display: none;
}

.bookmark-list {
  margin-top: 16px;
  height: 100%;
}

.bookmarks-empty {
  height: 100%;
  background-image: url("../images/illustrations/empty-bookmarks.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
.bookmarks-empty p {
  text-align: center;
  margin: 0 auto;
}
.bookmarks-empty .title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 80%;
  margin: 40px auto 0 auto;
}

.card {
  /* width: 100%; */
  padding: 16px;
  background-color: white;
  font-size: 1.3rem;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #E8EEFA;
  margin: 8px 0;
}

.bookmark-card {
  margin: 0 16px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 89%; */
}
.bookmark-card:hover {
  box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
}

.bookmark-card p {
  margin-right: 8px;
  pointer-events: none;
  margin: 0;
  font-weight: bold;
  width: 300px;
}

.bookmark-card img {
  pointer-events: none;
}

.bookmark-card > p {
  pointer-events: none;
}

.recent-list {
  margin-top: 16px;
}

.recent-card {
  margin: 0 16px 16px 16px;
}
.recent-card:hover {
  box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
}
.recent-card p {
  font-family: "Hind";
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  line-height: 150%;
  margin: 0;
}
.recent-card p:first-of-type {
  font-weight: 700;
}
.recent-card .last {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.recent-card .last img {
  width: 16px;
  height: 16px;
  margin-bottom: 3px;
}
.recent-card .last p {
  font-size: 1.4rem;
}
.recent-card .last p:first-of-type {
  margin-left: 8px;
  margin-right: 4px;
  font-weight: 700;
}

.scrubber {
  height: 80%;
  position: relative;
  width: 86px;
  position: absolute;
  top: 120px;
  left: 0;
  z-index: 1000;
  margin-left: 16px;
}
.scrubber .MuiSlider-root.MuiSlider-vertical {
  padding: 0;
  margin: 0 auto;
  display: block;
}
.scrubber .MuiSlider-rail {
  opacity: 1 !important;
  width: 4px !important;
  background-color: #5F636E !important;
  border-radius: 4px;
}
.scrubber .MuiSlider-track {
  opacity: 1 !important;
  width: 4px !important;
  background-color: #9BA1AD !important;
}
.scrubber .MuiSlider-thumb {
  width: 12px !important;
  height: 19px !important;
  margin-left: -4px !important;
  padding: 4px 0 !important;
  background-color: #161C29 !important;
  background-image: url("../images/scrubber/handle-rotated.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  border-radius: 4px !important;
  border: none;
  z-index: 1000;
}
.scrubber.bookmark-active .MuiSlider-thumb {
  background-color: #005A70 !important;
}
.scrubber .MuiSlider-thumb:hover, .scrubber .MuiSlider-thumb.Mui-focusVisible {
  /* box-shadow: 0px 0px 0px 6px rgba(0,0,0, 0.16); */
  box-shadow: 0px 0px 0px 8px rgba(0, 93, 131, 0.16) !important;
}
.scrubber .MuiSlider-thumb.MuiSlider-active {
  /* box-shadow: 0px 0px 0px 6px rgba(0,0,0, 0.16); */
  box-shadow: 0px 0px 0px 14px rgba(0, 93, 131, 0.16) !important;
}
.scrubber .bookmarks {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  pointer-events: none;
}
.scrubber .bookmark {
  width: 8px;
  height: 8px;
  left: 40px;
  display: block;
  margin: 0 auto;
  z-index: 999;
  position: absolute;
  pointer-events: auto;
  transition-delay: 300ms;
  background-color: #005A70;
  border: 1px solid #FEFEFE;
  border-radius: 24px;
  cursor: pointer;
}
.scrubber .history {
  height: 16px;
  width: 16px;
  position: absolute;
  left: 35.5px;
  bottom: 0;
  z-index: 2;
  fill: #05112A;
  cursor: pointer;
}
.scrubber .history circle {
  fill: #F7F9FD !important;
}
.scrubber .history.in-motion {
  fill: #9BA1AD;
}
.scrubber .history.bookmark-active {
  fill: #F7F9FD !important;
}
.scrubber .history.bookmark-active circle {
  fill: rgb(0, 101, 91) !important;
}

.toolbar {
  /* height:100vh; */
  height: 160px;
  width: 86px;
  left: 0;
  top: 12px;
  position: absolute;
  z-index: 2000;
  /* overflow:hidden; */
  border-radius: 0px 16px 16px 0px;
  box-shadow: 1px 3px 10px 0px rgba(221, 227, 238, 0.64), 1px -3px 10px 0px rgba(221, 227, 238, 0.64);
  background-color: #F7F9FD;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.toolbar.expanded {
  height: calc(100vh - 90px);
}

.toolbar .divider {
  height: 1px;
  width: 100%;
  display: block;
  background-color: #E8EEFA;
  margin: 10px 0;
  pointer-events: none;
}

.toolbar .tools-btn {
  width: 46px;
  height: 46px;
  border-radius: 40px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin: 2px 0px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar .tools-btn.home {
  background-image: url("../images/toolbar/home.svg");
}

.toolbar .tools-btn.aiBtn {
  background-image: url("../images/bot-icon-mini.svg");
  background-size: 40px 40px;
}

.toolbar .tools-btn.tocBtn {
  background-image: url("../images/toolbar/toc.svg");
}

.toolbar .tools-btn.tocBtn.active {
  background-image: url("../images/toolbar/toc-white.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.scrubber-btn {
  background-image: url("../images/toolbar/nav.svg");
}

.toolbar .tools-btn.scrubber-btn.active {
  background-image: url("../images/toolbar/nav-white.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.searchBtn {
  background-image: url("../images/toolbar/search.svg");
}

.toolbar .tools-btn.searchBtn.active {
  background-image: url("../images/toolbar/search-white.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.audioBtn {
  background-image: url("../images/toolbar/audio.svg");
}

.toolbar .tools-btn.audioBtn.active {
  background-image: url("../images/toolbar/audio-white.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.notebookBtn {
  background-image: url("../images/toolbar/notebook.svg");
}

.toolbar .tools-btn.notebookBtn.active {
  background-image: url("../images/toolbar/notebook-white.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.studyBtn {
  background-image: url("../images/toolbar/study.svg");
}

.toolbar .tools-btn.studyBtn.active {
  background-image: url("../images/toolbar/study-white.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.settingsBtn {
  background-image: url("../images/toolbar/settings.svg");
}

.toolbar .tools-btn.settingsBtn.active {
  background-image: url("../images/toolbar/settings-white.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.channelsBtn.active {
  background-image: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.postsBtn {
  background-image: url("../images/icons/posts-dark.svg");
}

.toolbar .tools-btn.postsBtn.active {
  background-image: url("../images/icons/posts-light.svg"), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}

.toolbar .tools-btn.close {
  background-image: url("../images/icons/close.svg");
}

.toolbar .tools-btn.more {
  background-image: url("../images/toolbar/more-vertical.svg");
}

.toolbar .tools-btn.close, .toolbar .tools-btn.more {
  position: absolute;
  bottom: 8px;
  left: 19px;
}
@media (max-height: 640px) {
  .toolbar .tools-btn.close, .toolbar .tools-btn.more {
    bottom: -100vh;
  }
}

.account-menu {
  position: absolute;
  z-index: 3000;
  top: 50px;
  right: 30px;
  width: 434px;
  box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
  background: white;
  border-radius: 16px;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.account-menu p {
  font-family: "Hind";
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  line-height: 150%;
  margin: 0;
  margin-left: 16px;
}

.account-menu .header {
  display: flex;
  justify-content: space-between;
  border-bottom: 6px solid rgba(155, 161, 172, 0.2);
  padding-top: 40px;
  padding-bottom: 16px;
}

.account-menu .header .title {
  font-family: "TT Commons";
  font-size: 4rem;
  font-weight: 700;
  font-style: normal;
  line-height: 100%;
  color: #1C222E;
}

.account-menu .header button {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.account-menu .header button img {
  pointer-events: none;
}

.account-menu ul {
  clear: both;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 0;
}

.account-menu ul .divider {
  height: 6px;
  width: 100%;
  display: block;
  background-color: rgba(155, 161, 172, 0.2);
}

.account-menu ul > li {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  border-top: 1px solid #E8EEFA;
  width: 110%;
  padding-left: 16px;
}

.account-menu ul > li button {
  /* @include p; */
  background: none;
  border: none;
  text-align: left;
  line-height: 150%;
  padding: 16px 16px 16px 0;
  display: flex;
  align-items: center;
}

.account-menu ul > li button:before {
  content: "";
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  background-image: url("../images/icons/chevron-right-mini.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  transition: all 300ms ease;
}

.account-menu ul > li button.expanded {
  transform: rotate(90deg);
  /* background-image:url('../images/icons/chevron-right-blue.svg'); */
}

.print {
  /* @include p; */
  width: 100%;
  padding: 16px;
  border-bottom: 6px solid rgba(155, 161, 172, 0.2);
  text-align: left;
}

.account-menu .get-app {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  border-bottom: 6px solid rgba(155, 161, 172, 0.2);
}

.account-menu .get-app div {
  margin-right: 24px;
}

.account-menu .get-app div p:first-of-type {
  font-weight: 700;
}

.account-menu .primary {
  display: block;
  margin: 20px auto;
  width: 140px;
  background: #1C222E !important;
  height: 40px;
}

@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.search .results-panel {
  height: calc(100% - 165px);
  overflow: auto;
}
.search .tab-container {
  width: 100%;
  padding: 0;
  margin-top: 24px;
}
.search .tab-container .tab-list-container {
  position: relative;
}
.search .tab-container .tab-slider {
  background: linear-gradient(135deg, #e49d2d, #ea0b7e 51.09%, #851b4a);
  bottom: 0;
  height: 4px;
  position: absolute;
  transition: left 0.3s ease, width 0.2s ease;
  width: auto;
}
.search .tab-container .tab-list {
  display: flex;
  gap: 16px;
  margin: 0;
  overflow-x: auto;
  justify-content: space-between;
  border-bottom: 1px solid #E8EEFA;
}
.search .tab-container .tab-list li {
  flex: 1;
}
.search .tab-container .tab-list li .tab-action {
  align-items: center;
  background: none;
  border: 0;
  border-bottom: 4px solid transparent;
  color: #020917;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 400;
  justify-content: space-between;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 6px 0 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}
.search .tab-container .tab-list li .tab-action[aria-selected=true] {
  border-bottom: 4px solid transparent;
  font-weight: 700;
}
.search .tab-container .tab-list li .tab-action .hidden-bold {
  font-weight: 700;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  visibility: hidden;
  width: 100%;
}
.search .search-panel .expand-collapse {
  display: flex;
  align-items: center;
  margin: 16px 0 16px 16px;
}
.search .search-panel .expand-collapse button, .search .search-panel .expand-collapse span {
  font-family: "TT Commons";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.24px;
}
.search .search-panel .expand-collapse button:hover {
  color: #655591;
}
.search .search-panel .chapters li {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid rgb(232, 238, 250);
}
.search .search-panel .chapters li img {
  transform: rotate(270deg);
  width: 12px;
  height: 12px;
  margin-right: 16px;
}
.search .search-panel .chapters button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  gap: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
.search .search-panel .chapters button p:last-of-type {
  display: block;
  white-space: nowrap;
}
.search .search-panel .chapters button:hover {
  text-decoration: underline;
}
.search .search-panel#panel-study .chapters li button, .search .search-panel#panel-custom .chapters li button {
  border-bottom: 1px solid rgb(232, 238, 250);
}
.search .search-panel#panel-study .chapters li img, .search .search-panel#panel-custom .chapters li img {
  transform: unset;
}
.search .search-panel#panel-custom .note-card {
  width: calc(100% - 74px);
  margin-left: 50px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: pointer;
}
.search .search-panel#panel-custom .note-card .highlight {
  padding: 8px;
  background: rgba(244, 217, 123, 0.25);
  border-left: 4px solid #EEA42E;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
.search .search-panel#panel-custom .note-card > p {
  margin: 0;
  margin-top: 16px;
}
.search .search-panel#panel-study .video-card {
  width: calc(100% - 74px);
  margin-left: 50px;
  margin-right: 24px;
  border-bottom: 1px solid rgb(232, 238, 250);
  padding-bottom: 16px;
  cursor: pointer;
}
.search .search-panel#panel-study .video-card .video-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search .search-panel#panel-study .video-card .video-header span {
  display: flex;
  align-items: center;
  gap: 6px;
}
.search .search-panel#panel-study .video-card .video-header span svg {
  width: 16px;
  height: 16px;
}
.search .search-panel#panel-study .video-card .video-header span span {
  font-size: 16px;
  font-weight: 400;
  color: rgb(5, 17, 42);
  margin-top: 2px;
}
.search .search-panel#panel-study .video-card .video {
  position: relative;
}
.search .search-panel#panel-study .video-card .video img {
  width: 100%;
}
.search .search-panel#panel-study .video-card .video svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.search.drawer-panel {
  height: calc(100vh - 90px);
  overflow-y: hidden;
  position: relative;
  padding-left: 16px;
}
.search .search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.search .search-container {
  background: rgb(254, 254, 254);
  border: 1px solid rgb(232, 238, 250);
  color: rgb(5, 17, 42);
  width: 306px;
  height: 40px;
  padding: 12px 4px 12px 16px;
  display: flex;
  align-items: center;
}
.search .search-container form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.search .search-container form img {
  width: 20px;
  height: 20px;
  margin-top: 6px;
}
.search .search-container input {
  background: transparent;
  border: none;
  margin-left: 16px;
  font-size: 16px;
  margin-top: 2px;
  width: 100%;
}
.search .search-header .filter-button {
  color: #6a7070;
  border: 1px solid rgb(232, 238, 250);
  background: rgb(254, 254, 254);
  width: 40px;
  height: 40px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search .search-header .filter-button[disabled] {
  opacity: 0.5;
}
.search .empty, .search .no-results {
  height: 100%;
  position: relative;
}
.search .empty:after, .search .no-results:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 112%;
  height: 521px;
  background-repeat: no-repeat;
  background-position: center;
}
.search .empty .title, .search .no-results .title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 80%;
  margin: 40px auto 0 auto;
}
.search .empty .p, .search .no-results .p {
  text-align: center;
  margin: 0 auto;
}
.search .empty:after {
  bottom: 88px;
  background-image: url("../images/illustrations/search-empty.svg");
}
.search .no-results:after {
  bottom: 0;
  background-image: url("../images/illustrations/search-no-results.svg");
}
.search .no-results button.primary {
  margin: 20px auto;
  display: block;
}
.search .spinner {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px auto;
  transform-origin: center;
  animation: 1500ms infinite linear spinAnimation;
}
.search .ai-overview-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 32px);
  margin: 16px 16px -1px 16px;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid var(--Border-lightTheme--border, #DDE3EE);
  background: var(--background-light-theme-bg-level-2, #E8EEFA);
}
.search .ai-overview-btn > div {
  display: flex;
  align-items: center;
  font-family: "TT Commons";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
}
.search .ai-overview-btn > div img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.search .ai-overview-btn.expanded {
  border-radius: 8px 8px 0 0;
}
.search .ai-overview-btn.expanded > img {
  transform: rotate(180deg);
}
.search .ai-response {
  padding: 0 16px;
  overflow-y: auto;
  padding-bottom: 40px;
  margin: 0 16px 0 16px;
  padding: 8px 20px;
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--Border-lightTheme--border, #DDE3EE);
}
.search .ai-response p {
  font-family: "TT Commons";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.search .ai-response p b {
  text-decoration: underline;
}
.search .ai-response > span {
  font-size: 18px;
}
.search .ai-response button.secondary {
  padding: 0 16px;
}
.search .ai-response button.secondary:hover {
  background-color: rgb(39, 45, 57);
  color: white;
}
.search .ai-response .more-btns {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 24px auto;
}
.search .ai-response h2 {
  color: #000;
  font-family: "TT Commons";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.4px;
  border-bottom: 1px solid #DDE3EE;
}
.search .ai-response ul {
  margin-bottom: 16px;
}
.search .ai-response ul.learn-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.search .ai-response ul li.article {
  background: #D9D9D9;
  height: 72px;
  flex: 1;
  cursor: pointer;
}
.search .ai-response ul li button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  border-radius: 8px;
  border: 1px solid var(--Border-lightTheme--border, #DDE3EE);
  margin-bottom: 8px;
  font-size: 18px;
  text-align: left;
}
.search .ai-response ul li button span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search .ai-response ul li button:hover {
  background-color: #eaeffa;
}
.search .ai-response .ai-feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search .ai-response .ai-feedback .ai-rating {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search .ai-response .ai-feedback .ai-rating button {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-container {
  display: flex;
  align-items: center;
}
.audio-container .timestamp {
  color: var(--text-light-theme-muted, #60646D);
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-family: TT Commons;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  margin-right: 8px;
  width: 70px;
}
.audio-container .audio-button {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin: 2px 0px;
  flex-shrink: 0;
  background-image: url("../images/toolbar/audio.svg");
}
.audio-container .audio-button.active {
  background-image: url("../images/toolbar/audio-white.svg"), linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
}
.audio-container .audio-button.playing {
  background-size: 42px;
  background-image: url("../images/gifs/audio_icon_dark_2024.gif");
}
.audio-container .audio-button.active.playing {
  background-size: 42px;
  background-image: url("../images/gifs/audio_icon_2024.gif");
}

.audio-player {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  transition: opacity 200ms ease;
  margin-left: 4px;
}

.audio-player button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 25px;
}
.audio-player button.notshowing {
  width: 0;
  height: 0;
  pointer-events: none;
}

.audio-player button:hover {
  background-color: #F7F9FD;
}

.audio-player > div span {
  font-size: 18px;
  font-weight: 500;
}

.audio-timeline {
  width: 100%;
  height: 8px;
  background: #B2B6BF;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.audio-timeline input[type=range] {
  width: 100%;
  height: 8px;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  outline: none;
  display: block;
  position: relative;
  z-index: 4;
  margin: 0;
}
.audio-timeline input[type=range]:focus {
  outline: none;
}
.audio-timeline input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}
.audio-timeline input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  border: none;
  cursor: pointer;
}

.playlist-settings {
  border: 1px solid rgb(112, 125, 137);
  width: 372px;
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
  position: relative;
  top: 170px;
  left: 100px;
}
.playlist-settings h2 {
  font-family: "TT Commons";
  font-size: 16px;
  font-weight: 700;
  color: #05112A;
}
.playlist-settings .playlist-settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.playlist-settings .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E8EEFA;
  padding: 8px 16px;
}
.playlist-settings .container .volume-container, .playlist-settings .container .playback-container {
  height: 38px;
  width: 38px;
  border-radius: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 300ms ease;
  z-index: 2;
}
.playlist-settings .container .volume-container:focus, .playlist-settings .container .playback-container:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #6D0176;
}
.playlist-settings .container .volume-container .volume {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/icons/volume.svg");
  pointer-events: none;
}
.playlist-settings .container .volume-container .volume.mute {
  background-image: url("../images/icons/mute.svg");
}
.playlist-settings .container .volume-container .input-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0s ease 500ms, width 300ms ease;
}
.playlist-settings .container .volume-container .input-container input[type=range] {
  display: block;
  -webkit-appearance: none;
  appearance: none;
  width: 96px;
  height: 4px;
  background: transparent;
  border-radius: 4px;
  outline: none;
  position: relative;
  z-index: 4;
}
.playlist-settings .container .volume-container .input-container input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  left: -4px;
  position: relative;
  padding: 4px 0;
  background-color: #1C222E;
  background-image: url("../images/icons/mini-handle.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  border: none;
  transition: all 100ms ease;
  z-index: 5;
}
.playlist-settings .container .volume-container .input-container input[type=range]::-moz-range-thumb {
  width: 10px;
  height: 12px;
  padding: 4px 0;
  background-color: #1C222E;
  background-image: url("../images/icons/mini-handle.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  border: none;
  transition: all 100ms ease;
  z-index: 5;
}
.playlist-settings .container .volume-container .input-container:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 4px;
  width: 96px;
  background: #9BA1AD;
  border-radius: 4px;
  z-index: 3;
}
.playlist-settings .container .volume-container .input-container:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: var(--volume-width);
  height: 5px;
  background-color: #1C222E;
  border-radius: 4px;
  z-index: 3;
}
.playlist-settings .container .volume-container.open {
  width: 144px;
}
.playlist-settings .container .volume-container.open .input-container {
  opacity: 1;
  width: 96px;
  pointer-events: auto;
}
.playlist-settings .container .playback-container span {
  font-size: 1.6rem;
  font-weight: 700;
  color: #1C222E;
  pointer-events: none;
}
.playlist-settings .container .playback-container button {
  display: none;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  opacity: 1;
}
.playlist-settings .container .playback-container button.minus {
  background-image: url("../images/icons/mini-minus.svg");
}
.playlist-settings .container .playback-container button.plus {
  background-image: url("../images/icons/mini-plus.svg");
}
.playlist-settings .container .playback-container button.hidden {
  opacity: 0;
}
.playlist-settings .container .playback-container.open {
  width: 144px;
  padding: 0 6px;
  justify-content: space-between;
}
.playlist-settings .container .playback-container.open button {
  display: block;
}

.channels {
  padding: 0 16px 0 20px;
  overflow-y: scroll;
  height: calc(100vh - 183px);
}
.channels .primary {
  width: 190px;
  height: 32px;
  margin: 20px auto 34px auto;
  display: block;
  font-size: 14px;
}
.channels .video-card {
  background: rgb(254, 254, 254);
  border-color: rgb(188, 193, 203);
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  width: 100%;
  min-height: 104px;
  padding: 12px 16px;
  margin: 0px;
  overflow: hidden;
  cursor: pointer;
  transition: border 0.25s ease 0s;
  margin-bottom: 16px;
}
.channels .video-card:hover, .channels .video-card.active {
  border-color: rgb(204, 1, 112);
}
.channels .video-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.channels .video-card .card-header p {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
}
.channels .video-card .card-header div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.channels .video-card img {
  width: 100%;
}
.channels .fake-select {
  margin: 16px 16px 0 16px;
  width: calc(100% - 32px);
}
.channels h3 {
  font-size: 16px;
  line-height: 24px;
  margin: 16px;
}
.channels .featured-video-card {
  width: 100%;
  position: relative;
  border-radius: 8px;
}
.channels .featured-video-card video {
  width: calc(100% + 34px);
  margin-left: -17px;
}
.channels .featured-video-card .title-header {
  display: flex;
  align-items: center;
  gap: 16px;
}
.channels .featured-video-card .tutor-img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid rgb(220, 220, 220);
  object-fit: cover;
}
.channels .featured-video-card p {
  margin: 0;
}
.channels .featured-video-card p.title {
  font-family: "TT Commons";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.5%;
  color: rgb(21, 21, 21);
  margin: 0px;
}
.channels .featured-video-card p.meta {
  color: rgb(0, 0, 0);
  font-family: "TT Commons";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
}

.display-settings {
  padding: 0 24px;
  padding-bottom: 100px;
  margin-top: 12px;
  margin-left: 16px;
}
.display-settings.light .category .label, .display-settings.light .category p {
  color: #1C222E;
}
.display-settings.dark .category .label, .display-settings.dark .category p {
  color: #F7F9FD;
}
.display-settings.dark .category.text-scale .input-container .littleA {
  background-image: url("../images/icons/littleA-white.svg");
}
.display-settings.dark .category.text-scale .input-container .bigA {
  background-image: url("../images/icons/bigA-white.svg");
}
.display-settings.dark .category.font-options .input-container ul li {
  color: white;
}
.display-settings .category {
  margin-top: 16px;
  padding-bottom: 16px;
}
.display-settings .category:not(:last-child) {
  border-bottom: 1px solid rgba(155, 161, 172, 0.2);
}
.display-settings .category p {
  font-family: "TT Commons";
  font-size: 1.8rem;
  font-weight: 700;
  font-style: normal;
  color: #1C222E;
}
.display-settings .category .input-container {
  display: flex;
}
.display-settings .category input:checked + label .label {
  font-weight: 600;
}
.display-settings .category .label {
  font-family: "Hind";
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  line-height: 150%;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 8px 0;
  text-align: center;
  cursor: pointer;
  width: 100%;
  display: block;
}
.display-settings .category.color-mode > div {
  display: flex;
}
.display-settings .category.color-mode .input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.display-settings .category.color-mode .input-container button {
  width: 110px;
  height: 53px;
  border-radius: 10px;
  padding: 2px 2px 2px 6px;
  position: relative;
  overflow: hidden;
  text-align: left;
}
.display-settings .category.color-mode .input-container button.light {
  border: 2px solid #F7F9FD;
  background-color: #FAFAFA;
}
.display-settings .category.color-mode .input-container button.light.active {
  border: 3px solid #6D0176;
}
.display-settings .category.color-mode .input-container button.sepia {
  border: 2px solid #F4F2E4;
  background-color: #F4F2E4;
  margin: 0 8px;
}
.display-settings .category.color-mode .input-container button.sepia.active {
  border: 3px solid #E9E3BD;
}
.display-settings .category.color-mode .input-container button.dark {
  border: 2px solid #222222;
  background-color: #222222;
}
.display-settings .category.color-mode .input-container button.dark span {
  color: white;
}
.display-settings .category.color-mode .input-container button.dark.active {
  border: 3px solid #F7F9FD;
}
.display-settings .category.color-mode .input-container button:not(:last-of-type) {
  margin-right: 8px;
}
.display-settings .category.color-mode .input-container button span {
  color: #222222;
  font-family: serif;
  line-height: 16px;
  font-size: 1.8rem;
  hyphens: none;
  display: block;
  text-align: center;
}
.display-settings .category.color-mode .input-container label.active {
  font-weight: 600;
}
.display-settings .category.text-scale .input-container .littleA, .display-settings .category.text-scale .input-container .bigA {
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 11px;
}
.display-settings .category.text-scale .input-container .littleA {
  background-image: url("../images/icons/littleA.svg");
}
.display-settings .category.text-scale .input-container .bigA {
  background-image: url("../images/icons/bigA.svg");
}
.display-settings .category.text-scale .input-container .MuiSlider-root {
  width: 85%;
  margin: 10px;
}
.display-settings .category.text-scale .input-container .MuiSlider-rail {
  opacity: 1;
  height: 2px;
  background-color: #C7C7C7;
}
.display-settings .category.text-scale .input-container .MuiSlider-track {
  width: 100%;
  height: 2px;
  background-color: #C7C7C7;
}
.display-settings .category.text-scale .input-container .MuiSlider-thumb {
  background: #6D0176;
}
.display-settings .category.text-scale .input-container .MuiSlider-thumb:hover, .display-settings .category.text-scale .input-container .MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(4, 122, 156, 0.16);
}
.display-settings .category.text-scale .input-container .MuiSlider-thumb.MuiSlider-active {
  box-shadow: 0px 0px 0px 14px rgba(4, 122, 156, 0.16);
}
.display-settings .category.character-case .input-container, .display-settings .category.line-spacing .input-container, .display-settings .category.page-progression .input-container {
  justify-content: space-between;
  background-color: #E8EEFA;
  border: 1px solid #D5DBE7;
  height: 40px;
  border-radius: 40px;
  padding: 3px 4px 4px 4px;
}
.display-settings .category.character-case .input-container input:checked + label, .display-settings .category.line-spacing .input-container input:checked + label, .display-settings .category.page-progression .input-container input:checked + label {
  background-color: #F7F9FD;
  border: 1px solid #D5DBE7;
}
.display-settings .category.character-case .input-container input:checked + label span, .display-settings .category.line-spacing .input-container input:checked + label span, .display-settings .category.page-progression .input-container input:checked + label span {
  font-weight: 600;
  color: #6D0176;
}
.display-settings .category.character-case .input-container label, .display-settings .category.line-spacing .input-container label, .display-settings .category.page-progression .input-container label {
  width: 50%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
}
.display-settings .category.character-case .input-container label span, .display-settings .category.line-spacing .input-container label span, .display-settings .category.page-progression .input-container label span {
  font-family: "Hind";
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  line-height: 150%;
}
.display-settings .category.show-highlights label {
  font-size: 1.6rem;
  font-weight: 400;
}
.display-settings .category.show-highlights .toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.display-settings .category.color-mode p, .display-settings .category.character-case p, .display-settings .category.line-spacing p, .display-settings .category.highlight-colors p, .display-settings .category.page-progression p {
  margin-bottom: 8px;
}
.display-settings .category.color-mode .input-container input, .display-settings .category.character-case .input-container input, .display-settings .category.line-spacing .input-container input, .display-settings .category.highlight-colors .input-container input, .display-settings .category.page-progression .input-container input {
  opacity: 0;
  width: 0;
  height: 0;
  margin-left: -3px;
}

.customize-menu {
  width: 400px;
  padding: 24px;
  background: rgb(254, 254, 254);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  top: unset;
  left: unset;
  transform: unset;
  cursor: move;
}
.customize-menu .customize-header {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(232, 238, 250);
  padding-bottom: 16px;
}
.customize-menu h2 {
  font-family: "TT Commons";
  font-size: 3rem;
  font-weight: 700;
  color: #05112A;
  margin: 0;
}
.customize-menu p {
  margin: 20px 0;
}
.customize-menu .container {
  display: flex;
  border-top: 1px solid #E8EEFA;
  padding: 18px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.customize-menu .container label {
  font-size: 1.6rem;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}
.customize-menu .container > div {
  display: flex;
  align-items: center;
  margin-right: 60px;
}
.customize-menu .container > div .toggle {
  margin-right: 20px;
}
.customize-menu .container > div img {
  margin-right: 8px;
}
.customize-menu button.toggle.on {
  background-color: rgb(5, 17, 42);
}

